
































































































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { DateFilterDialogProperties } from "@/components/shared/DateFilterDialogView.vue";
import ListViewMixin from "@/components/shared/ListViewMixin";
import LiveSessionService from "@/services/LiveSessionService";
import SpreadsheetService from "@/services/SpreadsheetService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import RouteUtil from "@/utilities/RouteUtil";
import StringUtil from "@/utilities/StringUtil";
import DateUtil from "@/utilities/DateUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import FilterUtil from '@/utilities/FilterUtil';

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const DateFilterDialogView = () => import("@/components/shared/DateFilterDialogView.vue");

@Component({
    components: { AlertDialogView, DateFilterDialogView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            loading: false,
        },
        filter: {
            uniqueNo: "",
            sessionDateFrom: null,
            sessionDateTo: null,
            startedDateNull: false,
            startedDateFrom: null,
            startedDateTo: null,
            endedDateNull: false,
            endedDateFrom: null,
            endedDateTo: null,
            status: "All"
        },
        liveSessions: [],
    }),
})
export default class LiveSessionListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private dateFilterDialog = new DateFilterDialogProperties();
    private liveSessionService = new LiveSessionService();
	private spreadsheetService = new SpreadsheetService();
    private dateTimeFormat: string = null;
    private dateFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.session-no"),
                value: "uniqueNo",
                width: "200px",
            },
            {
                text: this.$t("text.session-date"),
                value: "sessionDate",
                width: "200px",
            },
            {
                text: this.$t("text.status"),
                value: "status",
                width: "180px"
            },
            {
                text: this.$t("text.started-date"),
                value: "startedDate",
                width: "200px"
            },
            {
                text: this.$t("text.ended-date"),
                value: "endedDate",
                width: "200px"
            },
            {
                text: "",
                value: "dummy"
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "164px",
                sortable: false,
            },
        ];
    }

    public statusOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t('text.all'),
                value: "All",
                all: true
            },
            {
                text: this.$t('text.preparing'),
                value: "Preparing",
                all: false
            },
            {
                text: this.$t('text.started'),
                value: "Started",
                all: false
            },
            {
                text: this.$t('text.ended'),
                value: "Ended",
                all: false
            },
            {
                text: this.$t('text.closed'),
                value: "Closed",
                all: false
            }
        ].filter(x => !x.all || withAll);
    }

    public isPreparing(item: any) {
        return item.status === "Preparing";
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatStatus(v: any) {
        return this.statusOptions().find(x => x.value === v).text;
    }

    public created() {
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt((query.pageNo as any) ?? 1);
        data.table.recordCount = parseInt((query.recordCount as any) ?? 10);

        data.filter.uniqueNo = query.sessionNo ?? "";
        data.filter.sessionDateFrom = FilterUtil.dateOf(query.sessionDateFrom);
        data.filter.sessionDateTo = FilterUtil.dateOf(query.sessionDateTo);
        data.filter.startedDateNull = FilterUtil.booleanOf(query.startedDateNull);
        data.filter.startedDateFrom = FilterUtil.dateOf(query.startedDateFrom);
        data.filter.startedDateTo = FilterUtil.dateOf(query.startedDateTo);
        data.filter.endedDateNull = FilterUtil.booleanOf(query.endedDateNull);
        data.filter.endedDateFrom = FilterUtil.dateOf(query.endedDateFrom);
        data.filter.endedDateTo = FilterUtil.dateOf(query.endedDateTo);
        data.filter.status = query.status ?? "All";

        this.load();
    }

    public destroyed() {
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const status = filter.status === 'All' ? null : filter.status;

            const r = await this.liveSessionService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                uniqueNo: StringUtil.enclose(filter.uniqueNo, "%", "%"),
                sessionDate: FilterUtil.filterDate(
                    false, 
                    filter.sessionDateFrom, 
                    filter.sessionDateTo
                ),
                startedDate: FilterUtil.filterDate(
                    filter.startedDateNull, 
                    filter.startedDateFrom, 
                    filter.startedDateTo
                ),
                endedDate: FilterUtil.filterDate(
                    filter.endedDateNull,
                    filter.endedDateFrom,
                    filter.endedDateTo
                ),
                status
            });
            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.dateFormat = ResponseUtil.getDateFormat(r);

            const liveSessions = r.data.liveSessions;
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            this.$data.liveSessions = liveSessions.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/live-sessions", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                sessionNo: filter.uniqueNo,
                sessionDateFrom: filter.sessionDateFrom,
                sessionDateTo: filter.sessionDateTo,
                startedDateNull: filter.startedDateNull,
                startedDateFrom: filter.startedDateFrom,
                startedDateTo: filter.startedDateTo,
                endedDateNull: filter.endedDateNull,
                endedDateFrom: filter.endedDateFrom,
                endedDateTo: filter.endedDateTo,
                status
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public removeDialog(record: any) {
        AlertDialogProperties.delete(this.alertDialog, record.code);
        this.alertDialog.tag = { command: "remove", record };
        this.alertDialog.visible = true;
    }

    public async remove(record: any) {
        try {
            await this.liveSessionService.delete(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public filterSessionDate() {
        const filter = this.$data.filter;
        this.dateFilterDialog.nullable = false;
        this.dateFilterDialog.dateNull = false;
        this.dateFilterDialog.dateFrom = filter.sessionDateFrom;
        this.dateFilterDialog.dateTo = filter.sessionDateTo;
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.title = this.$t("text.session-date").toString();
        this.dateFilterDialog.labelDateFrom = this.$t("text.date-from").toString();
        this.dateFilterDialog.labelDateTo = this.$t("text.date-to").toString();
        this.dateFilterDialog.tag = "session-date";
        this.dateFilterDialog.visible = true;
    }

    public filterStartedDate() {
        const filter = this.$data.filter;
        this.dateFilterDialog.nullable = true;
        this.dateFilterDialog.dateNull = filter.startedDateNull;
        this.dateFilterDialog.dateFrom = filter.startedDateFrom;
        this.dateFilterDialog.dateTo = filter.startedDateTo;
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.title = this.$t("text.started-date").toString();
        this.dateFilterDialog.labelDateNull = this.$t("text.empty-date").toString();
        this.dateFilterDialog.labelDateFrom = this.$t("text.date-from").toString();
        this.dateFilterDialog.labelDateTo = this.$t("text.date-to").toString();
        this.dateFilterDialog.tag = "started-date";
        this.dateFilterDialog.visible = true;
    }

    public filterEndedDate() {
        const filter = this.$data.filter;
        this.dateFilterDialog.nullable = true;
        this.dateFilterDialog.dateNull = filter.endedDateNull;
        this.dateFilterDialog.dateFrom = filter.endedDateFrom;
        this.dateFilterDialog.dateTo = filter.endedDateTo;
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.title = this.$t("text.ended-date").toString();
        this.dateFilterDialog.labelDateNull = this.$t("text.empty-date").toString();
        this.dateFilterDialog.labelDateFrom = this.$t("text.date-from").toString();
        this.dateFilterDialog.labelDateTo = this.$t("text.date-to").toString();
        this.dateFilterDialog.tag = "ended-date";
        this.dateFilterDialog.visible = true;
    }

    public async filteredDate() {
        const filter = this.$data.filter;
        const tag = this.dateFilterDialog.tag;
        if (tag === "session-date") {
            filter.sessionDateFrom = this.dateFilterDialog.dateFrom;
            filter.sessionDateTo = this.dateFilterDialog.dateTo;
            await this.load();
        } else if (tag === "started-date") {
            filter.startedDateNull = this.dateFilterDialog.dateNull;
            filter.startedDateFrom = this.dateFilterDialog.dateFrom;
            filter.startedDateTo = this.dateFilterDialog.dateTo;
            await this.load();
        } else if (tag === "ended-date") {
			filter.endedDateNull = this.dateFilterDialog.dateNull;
			filter.endedDateFrom = this.dateFilterDialog.dateFrom;
			filter.endedDateTo = this.dateFilterDialog.dateTo;
			await this.load();
		}
    }

	public exportData() {
		const filter = this.$data.filter;
		const status = filter.status === 'All' ? null : filter.status;
        
        const url = this.spreadsheetService.liveSessionUrl("xlsx", {
			sessionNo: StringUtil.enclose(filter.uniqueNo, "%", "%"),
			sessionDate: FilterUtil.filterDate(
				false, 
				filter.sessionDateFrom, 
				filter.sessionDateTo
			),
			startedDate: FilterUtil.filterDate(
				filter.startedDateNull, 
				filter.startedDateFrom, 
				filter.startedDateTo
			),
			endedDate: FilterUtil.filterDate(
				filter.endedDateNull,
				filter.endedDateFrom,
				filter.endedDateTo
			),
			status
        });

        window.open(url, "_blank");
	}

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;

        if (tag && tag.command === "remove") {
            if (button === this.$t("text.yes")) {
                await this.remove(tag.record);
            }
        }
    }
}
